/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies
 */
import Layout from '../components/layout';
import SEO from '../components/seo';
import Section from '../components/section';
import OGImage from '../images/reads-og-image.jpg';
import Image from '../components/image';

const ReadsPage = ( props ) => {
      const {
            location,
      } = props;

      const SeoImageUrl = `https://www.tomhirst.com${ OGImage }`;

      return (
          <Layout location={ location } noCta>
                <SEO
                    title="Book Recommendations For Freelancers"
                    description="I've read a lot of books. These are the ones that I recommend for people who work for themselves."
                    imageAlt="Books Recommendations For Freelancers"
                    imageUrl={ SeoImageUrl }
                />
                <Section narrow>
                      <h1>Book Recommendations For Freelancers</h1>
                      <p>As someone who has been self-employed for the last 11 years, I&apos;ve read a lot of books
                            on business, marketing and the running of small companies.</p>
                      <p>Here are some of my most recommended reads for freelancers:</p>
                      <a className="resource"
                         href="http://a-fwd.to/p891OLf"
                         target="_blank">
                            <div className="resource-image">
                                  <Image
                                      name="atomic-habits.jpg"
                                      alt="Atomic Habits"
                                  />
                            </div>
                            <div className="resource-content">
                                  <h3>Atomic Habits</h3>
                                  <p>If you want to be more efficient, you need better habits.</p>
                                  <p className="resource-author">James Clear</p>
                            </div>
                      </a>
                      <a className="resource"
                         href="http://a-fwd.to/UDUX1HI"
                         target="_blank">
                            <div className="resource-image">
                                  <Image
                                      name="virtual-freedom.jpg"
                                      alt="Virtual Freedom"
                                  />
                            </div>
                            <div className="resource-content">
                                  <h3>Virtual Freedom</h3>
                                  <p>How to hire others to buy yourself more time.</p>
                                  <p className="resource-author">Chris Ducker</p>
                            </div>
                      </a>
                      <a className="resource"
                         href="http://a-fwd.to/2ddplun"
                         target="_blank">
                            <div className="resource-image">
                                  <Image
                                      name="this-is-marketing.jpg"
                                      alt="This Is Marketing"
                                  />
                            </div>
                            <div className="resource-content">
                                  <h3>This Is Marketing</h3>
                                  <p>A comprehensive guide to marketing.</p>
                                  <p className="resource-author">Seth Godin</p>
                            </div>
                      </a>
                      <a className="resource"
                         href="http://a-fwd.to/5tPDNAX"
                         target="_blank">
                            <div className="resource-image">
                                  <Image
                                      name="company-of-one.jpg"
                                      alt="Company Of One"
                                  />
                            </div>
                            <div className="resource-content">
                                  <h3>Company Of One</h3>
                                  <p>Why it&apos;s good to stay small in business.</p>
                                  <p className="resource-author">Paul Jarvis</p>
                            </div>
                      </a>
                      <a className="resource"
                         href="http://a-fwd.to/3jchw3w"
                         target="_blank">
                            <div className="resource-image">
                                  <Image
                                      name="leading.jpg"
                                      alt="Leading"
                                  />
                            </div>
                            <div className="resource-content">
                                  <h3>Leading</h3>
                                  <p>How to effectively get people to follow you.</p>
                                  <p className="resource-author">Alex Ferguson</p>
                            </div>
                      </a>
                      <a className="resource" href="http://a-fwd.to/437JuuQ"
                         target="_blank">
                            <div className="resource-image">
                                  <Image
                                      name="dmmt.jpg"
                                      alt="Don't Make Me Think"
                                  />
                            </div>
                            <div className="resource-content">
                                  <h3>Don't Make Me Think</h3>
                                  <p>Web usability bible that will help you create websites that convert.</p>
                                  <p className="resource-author">Steve Krug</p>
                            </div>
                      </a>
                      <a className="resource" href="https://jonathanstark.com/hbin"
                         target="_blank">
                            <div className="resource-image">
                                  <Image
                                      name="hbin.jpg"
                                      alt="Hourly Billing Is Nuts"
                                  />
                            </div>
                            <div className="resource-content">
                                  <h3>Hourly Billing Is Nuts</h3>
                                  <p>How to move away from exchanging your time for money.</p>
                                  <p className="resource-author">Jonathan Stark</p>
                            </div>
                      </a>
                </Section>
          </Layout>
      );
};

ReadsPage.propTypes = {
      location: PropTypes.objectOf( PropTypes.any ),
};

ReadsPage.defaultProps = {
      location: {},
};

export default ReadsPage;
